import { qspost } from '@/config/axios';

//获取数据
export const list = data => qspost("request?", data, "short_video_tag_list");

//添加数据
export const add = data => qspost("request?", data, "short_video_tag_add");

//编辑数据
export const edit = data => qspost("request?",data, "short_video_tag_edit");

//删除数据
export const del = data => qspost("request?",data, "short_video_tag_del");